export const menuLinks = [
  {
    id: 1,
    name: 'Заказы',
    icon: 'fa-truck',
    link: 'orders',
    show: true,
  },
  {
    id: 3,
    name: 'Клиенты',
    icon: 'fa-users',
    link: 'clients',
    show: true,
  },
  {
    id: 4,
    name: 'Услуги',
    icon: 'fa-edit',
    link: 'services',
    show: true,
  },
  {
    id: 5,
    name: 'Календарь смен',
    icon: 'fa-calendar',
    link: 'calendar',
    show: true,
  },
  {
    id: 1,
    name: 'Агрегатор',
    icon: 'fa-truck',
    link: 'agregator',
    show: true,
  },
  {
    id: 6,
    name: 'Профиль',
    icon: 'fa-user',
    link: 'profile',
    show: true,
  },
  {
    id: 7,
    name: 'Компания',
    icon: 'fa-users',
    link: 'company',
    show: true,
  },
  {
    id: 8,
    name: 'Пользователи',
    icon: 'fa-plus-circle',
    link: 'users',
    show: true,
  },
];
