<template>
  <li v-if="linkInfo.show" class="menu-link">
    <router-link
      class="flex justify-start items-center"
      :to="'/' + linkInfo.link"
    >
      <i class="fas mr-3" :class="linkInfo.icon"></i>
      <span class="menu-link-text font-semibold">{{ linkInfo.name }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'MenuLink',
  props: {
    linkInfo: {
      name: [String],
      link: [String],
      icon: [String],
      show: Boolean,
    },
  },
};
</script>

<style>
.menu-link {
  width: fit-content;
  padding: 13px 30px 13px 25px;
  background: transparent;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 0.5s ease;
  background: transparent;
}
.menu-link:hover {
  padding-right: 60px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #fff;
}
.menu-link > a > i {
  width: 16px;
}
.menu-link > a > .menu-link-text {
  font-size: 13px;
  font-weight: 600;
  font-family: latobold;
}
.menu-link > a > .menu-link-text,
.menu-link > a > i {
  color: #fff;
}
.menu-link:hover > a > .menu-link-text,
.menu-link:hover > a > i {
  color: rgb(34 197 94);
}
</style>
