import { createStore } from 'vuex';
import IMG from '@/store/API';
import API from '@/store/API';
import moment from 'moment';
import storage from '@/shared/utils/localstorage';

export default createStore({
  state: {
    alerts: [{ msg: 'У вас нет доступа', color: 'red' }],
    comments: [],
    s: {
      status: null,
      findme: null,
      category: null,
      type: null,
      pay: null,
      pay_status: null,
      manager: null,
      master: null,
      worker: null,
      month: moment().startOf('month').format('YYYY-MM-DD'),
      start: null,
      end: null,
      tab: null,
      calendar: 1,
      popup: null,
      back: null,
      backpop: null,
      clear: null,
      loader: null,
      delay: '',
      client: null,
      activity: 'all',
      page_in_client_arhive: 0,
      page_size_in_client_arhive: 10,
      box: '',
    },
    roles: [
      '',
      'Менеджер',
      'Логист',
      'Директор',
      'Инженер',
      'КК',
      'Клиент',
      '',
      'Admin',
      'Финансист',
      'Ассистент',
      'Бухгалтер',
    ],
    roleNames: {
      Assistant: 'Помощник',
      Manager: 'Менеджер',
      Logist: 'Логист',
      Studio: 'Директор',
      Master: 'Мастер',
      Check: 'Контроль',
      Client: 'Клиент',
      Buh: 'Бухгалтер',
      Finance: 'Финансист',
      Outsourcer: 'Подрядчик',
    },
    select_workers: '',
    select_managers: '',
    select_masters: '',
    select_boxes: '',
    select_prices: '',
    select_month: [
      '',
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    select_monthMin: [
      '',
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
    select_weekday: [
      '',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
      'Воскресенье',
    ],
    popup: false,
    loadForm: true,
    progressBar: '',
    result: '',
    activity: {},
  },

  mutations: {
    setAlert: (state, alert) => {
      state.alerts.unshift(alert);
    },
    setComments: (state, comments) => {
      state.comments = comments;
    },
    settings: (state, s) => {
      if (!state.s) {
        state.s = s;
      } else {
        Object.keys(s).forEach((k) => (state.s[k] = s[k]));
      }
    },
    setSelectWorkers: (state, workers) => {
      state.select_workers = workers;
    },
    setSelectManagers: (state, managers) => {
      state.select_managers = managers;
    },
    setSelectMasters: (state, masters) => {
      state.select_masters = masters;
    },
    setSelectBoxes: (state, boxes) => {
      state.select_boxes = boxes;
    },
    setSelectPrices: (state, prices) => {
      state.select_prices = prices;
    },
    setProgressBar: (state, progressBar) => {
      state.progressBar = progressBar;
    },
    setResult: (state, result) => {
      state.result = result;
    },
  },

  actions: {
    getSelects(ctx) {
      console.log('getSelects');
      API.GET('profile/selects').then((res) => {
        ctx.commit('setSelectWorkers', res.data.workers);
        ctx.commit('setSelectManagers', res.data.managers);
        ctx.commit('setSelectMasters', res.data.masters);
        ctx.commit('setSelectBoxes', res.data.boxes);
        ctx.commit('setSelectPrices', res.data.prices);
      });
    },

    setSettings(ctx, s) {
      // if (ctx.state.profile.role == "Master") {
      //     if (!ctx.state.s.start) {
      //         ctx.state.s.start = moment().format("YYYY-MM-DD");
      //     }
      //     if (!ctx.state.s.end) {
      //         ctx.state.s.end = moment().format("YYYY-MM-DD");
      //     }
      // }
      localStorage.setItem('s', JSON.stringify(s));
      ctx.commit('settings', s);
    },

    loadSettings(ctx) {
      // console.log("loadSettings", performance.now());
      let s = localStorage.getItem('s');
      if (s) {
        ctx.commit('settings', JSON.parse(s));
      }
    },

    clearSettings(ctx) {
      localStorage.removeItem('s');
      let s = {
        status: null,
        findme: null,
        category: 0,
        type: 0,
        pay: 0,
        pay_status: 0,
        manager: 0,
        master: 0,
        worker: 0,
        month: moment().format('YYYY-MM-DD'),
        start: null,
        end: null,
        tab: null,
        calendar: 1,
        popup: null,
        back: null,
        backpop: null,
        clear: null,
        loader: null,
        delay: '',
        client: null,
        activity: 'all',
        carRange: '0',
        page_in_client_arhive: 0,
        page_size_in_client_arhive: 10,
        box: '',
      };
      ctx.dispatch('setSettings', s);
    },

    setLoader(ctx, loader) {
      // console.log("setLoader", performance.now());
      ctx.state.s.loader = loader;
      ctx.dispatch('setSettings', ctx.state.s);
    },

    showPopup(ctx, popup = false) {
      if (popup) {
        ctx.state.s.popup = popup;
      } else {
        ctx.state.s.popup = true;
      }
      ctx.dispatch('setSettings', ctx.state.s);
    },

    closePopup(ctx) {
      ctx.state.s.popup = null;
      ctx.dispatch('setSettings', ctx.state.s);
    },

    // вернуть обратно и открыть popup если нужно (из backpop)
    goBack(ctx) {
      if (ctx.state.s.back) {
        ctx.state.s.popup = ctx.state.s.backpop;
        router.push(ctx.state.s.back);
        ctx.state.s.back = null;
        ctx.state.s.backpop = null;
        ctx.dispatch('setSettings', ctx.state.s);
      } else {
        ctx.state.s.popup = null;
        ctx.dispatch('setSettings', ctx.state.s);
      }
    },

    pluralize(n, words) {
      let idx;
      if (n % 10 === 1 && n % 100 !== 11) {
        idx = 0; // many
      } else if (
        n % 10 >= 2 &&
        n % 10 <= 4 &&
        (n % 100 < 10 || n % 100 >= 20)
      ) {
        idx = 1; // few
      } else {
        idx = 2; // one
      }
      return words[idx] || '';
    },

    pickMonth(ctx, date) {
      ctx.state.s.month = moment(date).format('YYYY-MM-DD');
      ctx.commit('settings', ctx.state.s);
    },

    setTab(ctx, tab) {
      ctx.state.s.tab = tab;
      localStorage.setItem('s', JSON.stringify(ctx.state.s));
      ctx.commit('settings', ctx.state.s);
    },

    getComments(ctx, formData) {
      API.GET('/ajax/comments', formData).then((res) => {
        let key = formData.type + formData.id;
        ctx.state.comments[key] = res.data;
        ctx.commit('setComments', ctx.state.comments);
      });
    },

    addComment(ctx, data) {
      console.log('formData key:', data.key);
      API.POST('/ajax/add-comment', data.formData).then((res) => {
        ctx.state.comments[data.key] = res.data;
        ctx.commit('setComments', ctx.state.comments);
      });
    },

    delComment(ctx, data) {
      ctx.state.comments[data.key] = ctx.state.comments[data.key].filter(
        (t) => t !== data.comment
      );
      API.POST('/ajax/del-comment', data.formData);
    },

    UploadCar(ctx, data) {
      ctx.state.loadForm = false;
      ctx.commit('setResult', null);
      // console.log(data);
      let formData = new FormData();
      for (var i = 0; i < data.e.target.files.length; i++) {
        let file = data.e.target.files[i];

        let ext = 'не определилось';
        let parts = file.name.split('.');
        if (parts.length > 1) {
          ext = parts.pop();
        }
        if (!['jpeg', 'jpg', 'png', 'bmp', 'pdf'].includes(ext)) {
          ctx.commit('set', 'Неправильный формат. Загрузите .jpg .png .pdf');
          ctx.state.loadForm = true;
          return;
        }
        formData.append('picture[' + i + ']', file);
      }

      formData.append('path', data.path);

      IMG.POST('storage/bucket/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function (progressEvent) {
          ctx.state.progressBar = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        }.bind(ctx),
      })
        .then((response) => {
          console.log('response', response);

          if (!data.item.images) {
            data.item.images = [];
          }
          console.log('beforeChanges', data.item);
          console.log('response.data.img', response.data.img);
          response.data.img.forEach((elem) => {
            data.item.images.push({
              img: elem,
              prefix: data.path,
              status: data.status,
              name: data.name,
            });
          });
          console.log('data.item.images', data.item.images);
          ctx.state.loadForm = true;
        })
        .catch((error) => console.log(error));
    },

    UploadUser(ctx, data) {
      ctx.state.loadForm = false;
      ctx.commit('setResult', null);
      const imagePrefix = API.PREFIX;
      // console.log(data);
      let formData = new FormData();
      for (var i = 0; i < data.e.target.files.length; i++) {
        let file = data.e.target.files[i];

        let ext = 'не определилось';
        let parts = file.name.split('.');
        if (parts.length > 1) {
          ext = parts.pop();
        }
        if (!['jpeg', 'jpg', 'png', 'bmp', 'pdf'].includes(ext)) {
          ctx.commit('set', 'Неправильный формат. Загрузите .jpg .png .pdf');
          ctx.state.loadForm = true;
          return;
        }
        formData.append('picture[' + i + ']', file);
      }

      formData.append('path', data.path);

      IMG.POST('storage/bucket/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function (progressEvent) {
          ctx.state.progressBar = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        }.bind(ctx),
      })
        .then((response) => {
          console.log('response', response);

          if (!data.item.images) {
            data.item.images = [];
          }
          console.log('123 this.item.images', data.item.images);
          console.log('beforeChanges', data.item);
          console.log('response.data.img', response.data.img);
          response.data.img.forEach((elem) => {
            data.item.images.push({
              img: elem,
              prefix: data.path,
              status: data.status,
              name: data.name,
            });
          });
          storage(
            'userAvatar',
            imagePrefix +
              data.item.images[0].prefix +
              '/' +
              data.item.images[0].img,
            'set'
          );
          // console.log('data.item.images', data.item.images);
          ctx.state.loadForm = true;
        })
        .catch((error) => console.log(error));
    },

    UploadAvatar(ctx, data) {
      ctx.state.loadForm = false;
      ctx.commit('setResult', null);
      // console.log(data);
      let formData = new FormData();
      for (var i = 0; i < data.e.target.files.length; i++) {
        let file = data.e.target.files[i];

        let ext = 'не определилось';
        let parts = file.name.split('.');
        if (parts.length > 1) {
          ext = parts.pop();
        }
        if (!['jpeg', 'jpg', 'png', 'bmp', 'pdf'].includes(ext)) {
          ctx.commit('set', 'Неправильный формат. Загрузите .jpg .png .pdf');
          ctx.state.loadForm = true;
          return;
        }
        formData.append('picture[' + i + ']', file);
      }

      formData.append('path', data.path);

      IMG.POST('storage/bucket/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function (progressEvent) {
          ctx.state.progressBar = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        }.bind(ctx),
      })
        .then((response) => {
          console.log('response', response);

          data.item.images = [];

          console.log('beforeChanges', data.item);
          console.log('response.data.img', response.data.img);
          response.data.img.forEach((elem) => {
            data.item.images.push({
              img: elem,
              prefix: data.path,
            });
          });
          console.log('data.item.images', data.item.images);
          ctx.state.loadForm = true;
        })
        .catch((error) => console.log(error));
    },

    Upload(ctx, data) {
      ctx.state.loadForm = false;
      ctx.commit('setResult', null);
      // console.log(data);
      let formData = new FormData();
      for (var i = 0; i < data.e.target.files.length; i++) {
        let file = data.e.target.files[i];

        let ext = 'не определилось';
        let parts = file.name.split('.');
        if (parts.length > 1) {
          ext = parts.pop();
        }
        if (!['jpeg', 'jpg', 'png', 'bmp', 'pdf'].includes(ext)) {
          ctx.commit('set', 'Неправильный формат. Загрузите .jpg .png .pdf');
          ctx.state.loadForm = true;
          return;
        }

        formData.append('picture[' + i + ']', file);
      }

      formData.append('objectName', data.type);
      formData.append(data.type, data.id);
      formData.append('status', data.status);
      formData.append(
        'order',
        JSON.stringify({
          order_id: data.id,
          order_from: data.order_from,
        })
      );
      formData.append(
        'user',
        JSON.stringify({
          user_id: data.user_id,
          from_url: data.user_from,
        })
      );

      IMG.POST('storage/order/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function (progressEvent) {
          ctx.state.progressBar = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        }.bind(ctx),
      })
        .then((response) => {
          console.log(response);

          console.log('data.item ', data.item);
          if (data.item == 'profile') {
            console.log('picture ', response.data.picture[0].img);
            console.log('profile ', ctx.rootState.profile.profile);
            ctx.rootState.profile.profile.picture =
              response.data.picture[0].img;
            ctx.commit('profile', ctx.rootState.profile.profile);
            return;
          }

          if (!data.item.images) {
            data.item.images = [];
          }
          console.log(data.item.images);
          response.data.picture.forEach((elem) => {
            data.item.images.push(elem);
          });
          console.log('data.item.images', data.item.images);
          ctx.state.loadForm = true;
        })
        .catch((error) => console.log(error));
    },

    async delImg(ctx, data) {
      console.log('delImg ' + data.img);
      data.item.images = data.item.images.filter((t) => t !== data.img);

      let formData = new FormData();
      formData.append('id', data.img.id);
      formData.append('oid', data.img.oid);
      IMG.POST('ajax/del-img', formData);

      const axios = require('axios');

      const options1 = {
        method: 'DELETE',
        url:
          process.env.VUE_APP_API_IMG_SERV +
          'storage/bucket/?path=' +
          data.path +
          '/' +
          data.img,
      };

      try {
        let response = await axios.request(options1);
        // console.log(response.data);
        console.log('response.data', response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },

  getters: {
    alerts(state) {
      return state.alerts;
    },
    s(state) {
      return state.s;
    },
    roles(state) {
      return state.roles;
    },
    roleNames(state) {
      return state.roleNames;
    },
    comments(state) {
      return state.comments;
    },
    selectWorkers(state) {
      return state.select_workers;
    },
    selectManagers(state) {
      return state.select_managers;
    },
    allMasters(state) {
      return state.select_masters;
    },
    selectMasters(state) {
      if (state.select_masters) {
        return state.select_masters.filter((m) => parseInt(m.status) > 0);
      }
    },
    selectBoxes(state) {
      return state.select_boxes;
    },
    selectPrices(state) {
      return state.select_prices;
    },
    selectMonth(state) {
      return state.select_month;
    },
    selectMonthMin(state) {
      return state.select_monthMin;
    },
    selectWeekDay(state) {
      return state.select_weekday;
    },
    popup(state) {
      return state.popup;
    },
    loadForm(state) {
      return state.loadForm;
    },
    progressBar(state) {
      return state.progressBar;
    },
    result(state) {
      return state.result;
    },
  },
});
