<template>
  <div class="pb-4 w-full flex flex-wrap items-center">
    <select v-model="itemsPerPage" @change="updatePage">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>

    <ul id="componentContainer" class="pagination-container">
      <li>
        <button href="" class="back-button paginate-buttons" @click="prevPage">
          &lt;
        </button>
      </li>
      <li v-for="(page, index) in pages" :key="page">
        <template
          v-if="
            index === 0 ||
            index === pages.length - 1 ||
            (index >= currentPage - 2 && index <= currentPage + 2)
          "
        >
          <button
            href=""
            class="paginate-buttons number-buttons"
            :class="{ 'active-page': currentPage === page }"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </template>
        <template
          v-else-if="index === currentPage - 3 || index === currentPage + 3"
        ></template>
        <template v-else-if="index === pages.length - 2">
          <button href="" class="ending-breakpoint-button paginate-buttons">
            ...
          </button>
        </template>
      </li>
      <li>
        <button href="" class="last-button paginate-buttons">
          {{ totalPages }}
        </button>
      </li>
      <li>
        <button href="" class="paginate-buttons next-button" @click="nextPage">
          &gt;
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'PaginationUI',
  props: {
    limit: {
      type: Number,
      required: true,
    },
    activePage: {
      type: Number,
      required: true,
    },
    fieldCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentPage: this.activePage,
      itemsPerPage: this.limit,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.fieldCount / this.itemsPerPage);
    },
    pages() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.$emit('page-changed', page);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.changePage(this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.changePage(this.currentPage + 1);
      }
    },
    updatePage() {
      this.$emit('items-per-page-changed', this.itemsPerPage);
      this.changePage(1);
    },
  },
};
</script>
<style scoped>
ul#componentContainer {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style-type: none;
  display: inline-flex;
}
.paginate-buttons {
  padding: 5px 10px;
  color: #fff;
  border-radius: 3px;
  background: #0f9af1;
  margin: 0 2px;
}

.active-page {
  background: #cbcbcb;
  color: #000;
}

ul#componentContainer li:nth-last-child(2) {
  display: none;
}
</style>
