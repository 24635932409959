<template>
  <div class="input__wrapper">
    <input
      id="input__file"
      name="file"
      type="file"
      class="input input__file"
      :value="modelValue"
      multiple
    />
    <label for="input__file" class="input__file-button">
      <span class="input__file-icon-wrapper"
        ><i class="fas fa-download"></i
      ></span>
      <span class="input__file-button-text">{{ labelInput }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FileUi',
  props: {
    labelInput: String,
    modelValue: [String, Number],
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style>
.input__wrapper {
  width: 100%;
  position: relative;
  text-align: center;
}

.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file-icon-wrapper {
  height: 40px;
  width: 40px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #fff;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
  font-size: 1rem;
  font-weight: 400;
  padding-top: 3px;
}

.input__file-button {
  width: 100%;
  max-width: 290px;
  height: 40px;
  background: #1bbc9b;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
}
</style>
