<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import MainLayout from '@/shared/layouts/MainLayout.vue';
import AuthLayout from '@/shared/layouts/AuthLayout.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'App',
  components: { MainLayout, AuthLayout },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    layout() {
      return this.$route.meta.layout + '-layout';
    },
  },
};
</script>

<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Hairline'), local('Lato-Hairline'),
    url('./assets/fonts/latohairline.woff') format('woff'),
    url('./assets/fonts/latohairline.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
    url('./assets/fonts/latohairlineitalic.woff') format('woff'),
    url('./assets/fonts/latohairlineitalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Thin'), local('Lato-Thin'),
    url('./assets/fonts/latothin.woff') format('woff'),
    url('./assets/fonts/latothin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Thin Italic'), local('Lato-ThinItalic'),
    url('./assets/fonts/latothinitalic.woff') format('woff'),
    url('./assets/fonts/latothinitalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Light'), local('Lato-Light'),
    url('./assets/fonts/latolight.woff') format('woff'),
    url('./assets/fonts/latolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
    url('./assets/fonts/latolightitalic.woff') format('woff'),
    url('./assets/fonts/latolightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Regular'), local('Lato-Regular'),
    url('./assets/fonts/latoregular.woff') format('woff'),
    url('./assets/fonts/latoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Italic'), local('Lato-Italic'),
    url('./assets/fonts/latoitalic.woff') format('woff'),
    url('./assets/fonts/latoitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Medium'), local('Lato-Medium'),
    url('./assets/fonts/latomedium.woff') format('woff'),
    url('./assets/fonts/latomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Medium Italic'), local('Lato-MediumItalic'),
    url('./assets/fonts/latomediumitalic.woff') format('woff'),
    url('./assets/fonts/latomediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Semibold'), local('Lato-Semibold'),
    url('./assets/fonts/latosemibold.woff') format('woff'),
    url('./assets/fonts/latosemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Semibold Italic'), local('Lato-SemiboldItalic'),
    url('./assets/fonts/latosemibolditalic.woff') format('woff'),
    url('./assets/fonts/latosemibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Bold'), local('Lato-Bold'),
    url('./assets/fonts/latobold.woff') format('woff'),
    url('./assets/fonts/latobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
    url('./assets/fonts/latobolditalic.woff') format('woff'),
    url('./assets/fonts/latobolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Heavy'), local('Lato-Heavy'),
    url('./assets/fonts/latoheavy.woff') format('woff'),
    url('./assets/fonts/latoheavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Heavy Italic'), local('Lato-HeavyItalic'),
    url('./assets/fonts/latoheavyitalic.woff') format('woff'),
    url('./assets/fonts/latoheavyitalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Black'), local('Lato-Black'),
    url('./assets/fonts/latoblack.woff') format('woff'),
    url('./assets/fonts/latoblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Black Italic'), local('Lato-BlackItalic'),
    url('./assets/fonts/latoblackitalic.woff') format('woff'),
    url('./assets/fonts/latoblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

.button-class-close {
  position: absolute;
  top: -78px;
  right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 10px !important;
}

#app {
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.border-required {
  border-bottom: 1px solid #f4487e !important;
}

.page-bg {
  background-color: #e2e8f0;
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.wrap-page {
  width: 100%;
  padding: 15px;
  max-height: 100vh;
  overflow-y: auto;
}
body {
  margin: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-long-enter-active,
.fade-long-leave-active {
  transition: opacity 1s ease;
}

.fade-long-enter-from,
.fade-long-leave-to {
  opacity: 0;
}
</style>
