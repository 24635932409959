<template>
  <div class="flex items-center mt-2">
    <input
      :id="'checkbox-' + labelInput"
      :disabled="disabled"
      :value="modelValue"
      type="checkbox"
      class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      @click="updateInput()"
    />
    <label
      :for="'checkbox-' + labelInput"
      class="ml-2 my-0 text-sm font-medium text-gray-400"
      style="line-height: 1"
      >{{ labelInput }}</label
    >
  </div>
</template>

<script>
export default {
  name: 'CheckBoxUI',
  props: {
    labelInput: String,
    modelValue: [String, Number],
    disabled: Boolean,
  },
  emits: ['update:modelValue'],
  methods: {
    updateInput() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style></style>
