import axios from 'axios';
// import authHeader from './header';
var authHeader = {
  Authorization: `Bearer LAbfPEqJYE-I6Cl-CyXxgzGA`,
};
//const API = "https://api.tahocrm.ru/";
// const API = 'https://apidev.tahocrm.ru/';
// const API = 'http://localhost:8000/';
const IMG = process.env.VUE_APP_API_IMG_SERV;
const API = process.env.VUE_APP_API_API_SERV;
const PREFIX = process.env.VUE_APP_API_PREFIX_SERV;

class UserService {
  GET(url, data, headers = null) {
    if (headers != null) {
      var specialHeader = {
        Authorization: authHeader.Authorization,
        userID: headers.userID,
        userRoles: headers.userRoles,
      };
      return axios.get(API + url, { params: data, headers: specialHeader });
    } else {
      return axios.get(API + url, { params: data, headers: authHeader });
    }
  }
  POST(url, formData, headers = null) {
    if (headers != null) {
      var specialHeader = {
        Authorization: authHeader.Authorization,
        userID: headers.userID,
        userRoles: headers.userRoles,
      };

      if (url.includes('storage')) {
        return axios.post(IMG + url, formData, {
          headers: specialHeader,
        });
      } else {
        return axios.post(API + url, formData, {
          headers: specialHeader,
        });
      }
    } else {
      console.log(formData);
      return axios.post(API + url, formData, { headers: authHeader });
    }
  }
}
UserService.prototype.URL = API;
UserService.prototype.IMG = IMG;
UserService.prototype.PREFIX = PREFIX;

export default new UserService();
